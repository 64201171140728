import { graphql } from 'gatsby'
import styled from 'styled-components'
import GatsbyHead from '../components/GatsbyHead'
import LayoutWrapper from '../components/LayoutWrapper'
import PageBanner from '../components/PageBanner'
import PageSection from '../components/PageSection'
import TemplateGroupListing from '../components/TemplateGroupListing'
import Wrapper from '../components/Wrapper'
import { useIntl } from '../utils/IntlContext'
import { getLocalePathPrefix, getLocalizedRootUrlPrefix, urlPathJoin } from '../utils/locales'
import { legacyBreakpoints } from '../utils/media'

const StyledWrapper = styled(Wrapper)`
  position: relative;
  padding: 100px 0;

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    padding: 70px 0;
  }
`

const Background = styled.div`
  background-color: var(--color-background-light);
`

export const query = graphql`
  query CVTemplates($contentful_id: String!, $locale: String!) {
    contentfulReactPage(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      id
      contentful_id
      slug
      node_locale
      languages
      meta_title
      meta_description
      banner {
        ...PageBanner
      }
      sections {
        ...PageSection
      }
    }
    templateGroups: allContentfulCvTemplateGroup(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: title, order: ASC }
    ) {
      edges {
        node {
          ...CvTemplateGroup
        }
      }
    }
    successStoriesPage: contentfulReactPage(
      contentful_id: { eq: "35SjrERFSPauSTpENszW1i" }
      node_locale: { eq: $locale }
    ) {
      slug
    }
  }
`

const CVTemplates = ({
  pageContext: { translatedPages },
  data: {
    contentfulReactPage: { contentful_id, slug, banner, sections },
    templateGroups,
    successStoriesPage,
  },
}) => {
  const { locale } = useIntl()
  const pageBasePath = urlPathJoin(getLocalePathPrefix(locale), slug)

  return (
    <LayoutWrapper translatedPages={translatedPages}>
      <PageBanner banner={banner} />
      <Background>
        <StyledWrapper>
          <TemplateGroupListing
            parentBasePath={pageBasePath}
            groups={templateGroups.edges.map(
              ({
                node: {
                  id,
                  title,
                  description: { description },
                  templates,
                },
              }) => ({
                id,
                title,
                description,
                items: templates,
              }),
            )}
          />
        </StyledWrapper>
      </Background>
      <PageSection
        contentfulId={contentful_id}
        successStoriesPath={getLocalizedRootUrlPrefix(successStoriesPage, locale)}
        sections={sections}
      />
    </LayoutWrapper>
  )
}

/** @type {import('gatsby').HeadFC} */
export const Head = ({
  pageContext: { canonicalUrl, translatedPages },
  data: {
    contentfulReactPage: { meta_title, meta_description },
  },
}) => {
  return (
    <GatsbyHead
      pageTitle={meta_title}
      metaDescription={meta_description}
      canonicalUrl={canonicalUrl}
      translatedPages={translatedPages}
    />
  )
}

export default CVTemplates
